export const сurrencies = {
  RUR: 'р.',
  EUR: '€',
  UAH: '₴',
  BYN: 'р.',
  KGS: 'сом',
  PLN: 'zł.',
  AMD: '֏',
  KZT: '₸',
  MDL: 'MDL',
  TRY: '₺',
  RSD: 'RSD',
};

export const countryIdStorageName = 'countryId';
