import { useSelectCity } from '../../model/selectors';
import styles from './current-city-btn.module.scss';

export function CurrentCityBtn({ action }: { action: () => void }) {
  const city = useSelectCity();

  return (
    <button type="button" className={styles.city} onClick={action}>
      {city.city_name}
    </button>
  );
}
