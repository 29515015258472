import { stringToDate, isBefore, isAfter } from 'src/shared/lib/date';
import globalStore from 'modules/global-store';
import { countriesAllowed, holydaysSpans } from '../model/consts';

type countryKeys = keyof typeof countriesAllowed;

type IHolidayCheck = countryKeys | undefined | null;

export const holidayCheck = (): IHolidayCheck => {
  const currentDate = new Date();
  const { country_id }: { country_id: number } =
    globalStore.get('current_city');

  const holidayName = holydaysSpans.find(({ start, end }) => {
    return (
      isAfter(currentDate, stringToDate(start)) &&
      isBefore(currentDate, stringToDate(end))
    ); // not include
  })?.name;

  if (
    !holidayName ||
    !countriesAllowed[holidayName] ||
    !countriesAllowed[holidayName].includes(country_id)
  )
    return null;

  return holidayName;
};
