import { HeaderPhone } from 'frontend/widgets/header/headerPhone/HeaderPhone';
import { ProfileButton } from 'frontend/widgets/header/profileButton/ProfileButton';
import { SelectLanguage } from 'features/select-language';
import loadable from '@loadable/component';
import { SideBarOpener } from 'frontend/widgets/sideBar';
import { HorizontalHeaderMenu } from 'src/entities/header-menu';
import { useSelectCityModal } from 'features/modals/select-city-modal';
import { CurrentCityBtn } from 'src/entities/cities';
import { CartLink } from '../cartLink/CartLink';
import { PromotionsLink } from '../promotionsLink/PromotionsLink';
import styles from './desktop.module.scss';
import { Logo } from '../logo/Logo';

const DecorationSpan = loadable(
  () =>
    import('src/entities/holyday-decorations').then(
      ({ DecorationSpan: Decoration }) => Decoration
    ),
  { ssr: false }
);

function Desktop() {
  const cityModal = useSelectCityModal();
  return (
    <>
      <div className={styles.leftBlock}>
        <SideBarOpener />
        <Logo />
        <SelectLanguage />
        <CurrentCityBtn action={cityModal.show} />
        <HeaderPhone />
      </div>
      <DecorationSpan position="left" />
      <HorizontalHeaderMenu />
      <DecorationSpan position="right" />
      <div className={styles.rightBlock}>
        <ProfileButton />
        <PromotionsLink />
        <CartLink />
      </div>
    </>
  );
}

export { Desktop };
