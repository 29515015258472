import { useTranslation } from 'react-i18next';
import { useSelectedCountry } from 'src/entities/countries';
import cn from 'classnames';
import RuLogoIcon from 'assets/images/icon/logo.svg';
import { SmartLink } from 'shared/ui/smart-link';
import { holidayCheck } from 'src/entities/holyday-decorations';
import styles from './logo.module.scss';
import UaLogoIcon from './assets/uaLogo.svg';
import EnLogoIcon from './assets/enLogo.svg';
import HyLogoIcon from './assets/hyLogo.svg';
import HalloweenLogo from './assets/halloweenLogo.svg';

const useLogoIcon = () => {
  const {
    i18n: { language },
  } = useTranslation();
  const { isMontenegro, isUkraine, isArmenia } = useSelectedCountry();
  const holidayName = holidayCheck();

  if (isUkraine) return UaLogoIcon;
  if (isMontenegro) return EnLogoIcon;
  if (isArmenia) return HyLogoIcon;
  if (language !== 'ru') return EnLogoIcon;

  if (holidayName === 'halloween') return HalloweenLogo;

  return RuLogoIcon;
};

function Logo() {
  const LogoIcon = useLogoIcon();
  const logoStyle = cn(styles.img);
  const { t } = useTranslation();

  return (
    <SmartLink href="/">
      <LogoIcon
        role="img"
        aria-label={t('Logo.sushiwok_logo')}
        width={200}
        height={50}
        className={logoStyle}
      />
    </SmartLink>
  );
}

export { Logo };
