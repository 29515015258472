// import Hamster from 'frontend/widgets/hamster/Hamster';
import SupportChat from 'frontend/widgets/support-chat/SupportChat';
import Cookie from 'frontend/widgets/cookie/Cookie';
import { MediaContextProvider } from 'src/shared/lib/media';
import { ErrorBoundary } from 'react-error-boundary';
import Error from 'src/shared/ui/error';
import { useResetCartOnChangeCity } from 'features/cart-reset';
import { useCartQtyHandler } from 'features/add-product-to-cart/useCartQtyHandler';
import { useSelectAddressFirst } from 'features/select-address-first';
import { RoutesWithSubRoutes } from 'shared/lib/routes-with-sub-routes';
import { useCityChange } from 'src/entities/cities';
import { useFirstShowConfirmCityModal } from 'features/modals/confirm-city-modal';
import { useSelectCityModal } from 'features/modals/select-city-modal';
import routes from './routes';
import { Theme } from './theme';
import SwRegister from './sw-register';

function App() {
  const { hasCityChanged } = useCityChange();
  useResetCartOnChangeCity(hasCityChanged);
  const selectCityModal = useSelectCityModal();
  useFirstShowConfirmCityModal(selectCityModal.show);
  useCartQtyHandler();
  useSelectAddressFirst();

  return (
    <MediaContextProvider>
      <Theme />
      <SwRegister />
      <ErrorBoundary FallbackComponent={Error}>
        <RoutesWithSubRoutes routes={routes} />
        {/* <Hamster /> */}
        <Cookie />
        <SupportChat />
      </ErrorBoundary>
    </MediaContextProvider>
  );
}

export default App;
