export const holydaysSpans = [
  // end date is not include
  {
    name: 'feb14',
    start: '12.02.2024',
    end: '19.02.2024',
  },
  {
    name: 'feb23',
    start: '20.02.2024',
    end: '27.02.2024',
  },
  {
    name: 'march8',
    start: '05.03.2024',
    end: '12.03.2024',
  },
  {
    name: 'birthday',
    start: '18.04.2023',
    end: '01.06.2023',
  },
  {
    name: 'halloween',
    start: '25.10.2024',
    end: '04.11.2024',
  },
  {
    name: 'newYear',
    start: '12.12.2023',
    end: '15.01.2024',
  },
] as const;

// Страны с праздничным оформлением
// 1-РФ, 3-Беларусь, 6-Киргизия
export const countriesAllowed = {
  feb14: [1, 3, 6],
  feb23: [1, 3],
  march8: [1, 3, 6],
  birthday: [1],
  halloween: [1],
  newYear: [1],
};
