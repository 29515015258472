import { useEffect, useRef, useState } from 'react';
import ballonImage from 'assets/images/icon/ballon-icon.svg';
import uaLocationIcon from 'assets/images/icon/uaLocationIcon.svg';
import { useSelectedCountry } from 'src/entities/countries';
import { IStore } from 'src/entities/stores';
import { useTranslation } from 'react-i18next';
import { LinkTabs } from 'shared/ui/link-tabs';
import { StoresTable } from 'src/entities/stores/ui/stores-table';
import Button from 'shared/ui/Button/Button';
import { Suggest } from 'features/address-suggest';
import { PickupMap } from 'features/stores-map';
import { modalEmitter } from 'modules/event-emitters';
import { Media } from 'shared/lib/media';
import { isAddressError, useGetAddressQuery } from 'src/entities/delivery';
import { useSelectCity } from 'src/entities/cities';
import { useCityAndLang } from 'shared/lib/use-city-and-lang';
import { PageTitle } from 'shared/ui/page-title';
import { skipToken } from '@reduxjs/toolkit/query';
import { scrollToExecution } from 'frontend/Components/NewCartOrder/Helpers';
import styles from './storesPageContent.module.scss';

function StoresPageContent({ stores }: { stores: IStore[] }) {
  const { is_delivery } = useSelectCity();
  const { t } = useTranslation();
  const { isUkraine } = useSelectedCountry();
  const [currentStoreId, setCurrentStoreId] = useState<number | null>(null);
  const [invalidAddress, setInvalidAddress] = useState<boolean | null>(false);
  const { city, lang } = useCityAndLang();
  const mapContaonerRef = useRef(null);

  useEffect(() => {
    if (currentStoreId && mapContaonerRef) {
      scrollToExecution(mapContaonerRef.current, 100);
    }
  }, [mapContaonerRef, currentStoreId]);

  const {
    city_name: cityName,
    latitude: lat,
    longitude: lng,
  } = useSelectCity();

  const [newAddress, setNewAddress] = useState<string | number[] | null>(null);

  const params = newAddress
    ? { coordsOrString: newAddress, cityName, cityTextId: city, lang }
    : skipToken;
  const { data: fullAddress = null } = useGetAddressQuery(params, {
    refetchOnMountOrArgChange: true,
  });

  const setAddress = (address: string) => {
    setNewAddress(address);
  };

  const setCurrentCenter = (coors: number[] | null) => {
    setNewAddress(coors);
  };

  const items = [
    {
      url: `/delivery`,
      title: t('localization.Delivery'),
    },
    {
      url: `/addresses`,
      title: t('localization.Stores'),
    },
  ];

  const addReview = (store: IStore) => {
    modalEmitter.emit('Review.Modal.Open', store);
  };

  const LocatioIcon = isUkraine ? uaLocationIcon : ballonImage;

  const newFullAddress = isAddressError(fullAddress) ? null : fullAddress;

  return (
    <>
      <div className={styles.storesHeader}>
        <Media greaterThan="laptop">
          {(className, renderChildren) => {
            return renderChildren ? (
              <PageTitle className={className}>
                {t('List.find nearest store')}
              </PageTitle>
            ) : null;
          }}
        </Media>
        {is_delivery && (
          <div className={styles.headerTabs}>
            <LinkTabs items={items} />
          </div>
        )}
      </div>
      <div className={styles.toolbarLeft}>
        <div className={styles.suggestionWrap}>
          <div
            role="button"
            className={styles.geolocationWrap}
            tabIndex={0}
            // onClick={getPosition}
          >
            <LocatioIcon />
          </div>
          <div style={{ flexGrow: '1' }}>
            <span className={styles.addressTitle}>
              {t('List.your address')}
            </span>
            <Suggest
              placeholder={t('List.Enter street or subway')}
              fullAddress={newFullAddress}
              setAddress={setAddress}
              cityCoords={{ lat, lng }}
              cityName={cityName}
              lang={lang}
              // onChange={onSuggestChange}
            />
            {invalidAddress && (
              <div className={styles.addressError}>
                {' '}
                {t('List.Update address')}{' '}
              </div>
            )}
          </div>
        </div>
        <Button
          className={styles.buttonContainer}
          text={t('List.find nearest store')}
          // onClick={getPosition}
        />
      </div>
      <div className={styles.content}>
        <div ref={mapContaonerRef} className={styles.map}>
          <PickupMap
            stores={stores}
            actionFn={addReview}
            fullAddress={newFullAddress}
            setCurrentCenter={setCurrentCenter}
            cityCoords={{ lat, lng }}
            currentStoreId={currentStoreId}
            setCurrentStoreId={setCurrentStoreId}
            btnText={t('Map.send_review')}
            onGetAddressError={(e: unknown) => {
              if (e === 'Address not found' || e === 'Address not complete') {
                setInvalidAddress(true);
              }
            }}
          />
        </div>
        <div className={styles.list}>
          <StoresTable stores={stores} setCurrentStoreId={setCurrentStoreId} />
        </div>
      </div>
    </>
  );
}

export default StoresPageContent;
