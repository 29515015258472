import styles from './info.module.scss';

export function InfoButton({ action }: { action: () => void }) {
  return (
    <button
      className={styles.infoButton}
      aria-label="compectation info"
      onClick={action}
      type="button"
    />
  );
}
