import { Burger } from 'frontend/widgets/header/burger/Burger';
// import { DecorationSpan } from 'src/entities/holyday-decorations';
import loadable from '@loadable/component';
import { Logo } from '../logo/Logo';
import styles from './mobile.module.scss';
import { CartLink } from '../cartLink/CartLink';

const DecorationSpan = loadable(
  () =>
    import('src/entities/holyday-decorations').then(
      ({ DecorationSpan: Decoration }) => Decoration
    ),
  { ssr: false }
);
function Mobile() {
  return (
    <div className={styles.root}>
      <Burger />
      <DecorationSpan position="left" />
      <Logo />
      <DecorationSpan position="right" />
      <CartLink isMobile />
    </div>
  );
}

export { Mobile };
