import '../Profile.scss';
import { useEffect, useState } from 'react';
import * as session from 'modules/session';
import api from 'modules/helpers/api';
import { userProfileEmitter } from 'modules/event-emitters';
import globalStore from 'modules/global-store';
import ProfileComponent from 'src/frontend/Components/Profile/Profile';
import { IOrder } from 'frontend/Pages/Profile/lib/IOrder';
import { IUserAddress } from 'src/entities/user';

function Profile() {
  const [addresses, setAddresses] = useState<IUserAddress[]>([]);
  const [orders, setOrders] = useState<IOrder[]>([]);

  const getAddress = () => {
    return api('user.addresses').then((result) =>
      globalStore.set('addresses', result)
    );
  };

  const updateAddress = () => {
    if (session.get('user')) {
      return getAddress()
        .then(() => {
          const user = session.get('user');
          user.addresses = globalStore.get('addresses');
          session.set('user', user);
          setAddresses(globalStore.get('addresses'));
        })
        .catch((error) => {
          console.log(error);
        });
    }
    setAddresses([]);
  };

  const getOrders = () => {
    return api('user.orders').then((result) =>
      globalStore.set('orders', result)
    );
  };

  const update = () => {
    const orders = globalStore.get('orders');
    const addresses = globalStore.get('addresses');

    if (session.get('user')) {
      Promise.all([getAddress(), getOrders()])
        .then(() => {
          if (
            orders !== globalStore.get('orders') ||
            addresses !== globalStore.get('addresses')
          ) {
            setAddresses(globalStore.get('addresses'));
            setOrders(globalStore.get('orders'));
          }
        })
        .catch(() => {
          setAddresses([]);
          setOrders([]);
        });
    }
  };

  useEffect(() => {
    update();
    // userEmitter.addListener('User.Change', this.update);
    userProfileEmitter.addListener('AddressEdit.update', updateAddress);

    return () => {
      // userEmitter.removeListener('User.Change', this.update);
      userProfileEmitter.removeListener('AddressEdit.update', updateAddress);
    };
  }, []);

  return (
    <ProfileComponent
      addresses={addresses}
      orders={orders}
      updateAddress={updateAddress}
    />
  );
}

export default Profile;
