import { useEffect } from 'react';
import { useMemorizer } from 'shared/lib/memorizer';
import { cityTextIdStorageName } from 'src/entities/cities';
import { useConfirmCityModal } from './handler';

export const useFirstShowConfirmCityModal = (showSelectCity: () => void) => {
  const { show } = useConfirmCityModal();
  const confirmCityMemorizer = useMemorizer(cityTextIdStorageName);
  const memorizeCityTextId = confirmCityMemorizer.get();
  useEffect(() => {
    if (!memorizeCityTextId) {
      show().then(showSelectCity);
    }
  }, [show, memorizeCityTextId, showSelectCity]);
};
