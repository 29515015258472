import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { modalEmitter } from 'modules/event-emitters';
import { IStore, StoreTimeTable } from 'src/entities/stores';
import styles from './storesTable.module.scss';
import { IStoreTableRow, SortingType } from '../model/types';
import {
  SchemeType,
  getScheme,
  getSchemeWithMetro,
} from '../model/columnScheme';
import { normalizeStoresTable, sortStoresTable } from '../lib';
import ColumnHead from './column-head/ColumnHead';
import AddressSell from './address-cell/AddressSell';
import HoursCell from './hours-cell/HoursCell';

export interface IStoresTable {
  stores: IStore[];
  setCurrentStoreId: (id: number) => void;
}

function StoresTable({ stores, setCurrentStoreId }: IStoresTable) {
  const { t } = useTranslation();
  const isSubway = !!stores.find((store) => !!store.subway);
  const scheme = isSubway ? getSchemeWithMetro(t) : getScheme(t);
  const [sorting, setSorting] = useState<SortingType>(null);
  const [sortingColumn, setSortingColumn] = useState<keyof SchemeType | null>(
    null
  );
  const normStores = useMemo<IStoreTableRow[]>(
    () => normalizeStoresTable({ stores }),
    [stores]
  );
  const [storesToShow, setStoresToShow] =
    useState<IStoreTableRow[]>(normStores);
  const [showScheduleId, setShowScheduleId] = useState<number | null>(null);

  useEffect(() => {
    setStoresToShow(
      sortStoresTable({
        stores: normStores,
        sorting,
        sortingColumn,
      })
    );
  }, [normStores, sorting, sortingColumn]);

  if (!storesToShow.length) return null;

  const setSortingColumnDirection = (col: keyof typeof scheme) => {
    if (!scheme[col].sorting) return;
    if (col === sortingColumn) {
      setSorting(sorting === 'up' ? 'down' : 'up');
      return;
    }
    setSortingColumn(col);
    setSorting('up');
  };

  const showSchedule = (storeId: number) => {
    if (showScheduleId === storeId) {
      setShowScheduleId(null);
      return;
    }
    setShowScheduleId(storeId);
  };

  const getStoreById = (storeId: number) => {
    return stores.find((store) => store.id === storeId);
  };

  const writeReview = (id: number) => {
    modalEmitter.emit('Review.Modal.Open', getStoreById(id));
  };

  return (
    <table className={styles.tableContainer}>
      <thead className={styles.tableHeader}>
        <tr>
          {Object.entries(scheme).map(([key, value]) => {
            return (
              <ColumnHead
                key={key}
                sorting={sortingColumn === key ? sorting : null}
                title={value.title}
                onClick={() =>
                  setSortingColumnDirection(key as keyof typeof scheme)
                }
              />
            );
          })}
        </tr>
      </thead>
      <tbody>
        {storesToShow.map(({ id, address, subway, phone, workingHours }) => {
          return (
            <tr key={id} className={styles.tableLine}>
              <td>
                <AddressSell
                  address={address}
                  onClick={() => setCurrentStoreId(id)}
                />
              </td>
              <td>{subway}</td>
              <td>{phone}</td>
              <td style={{ position: 'relative' }}>
                <HoursCell {...workingHours} onClick={() => showSchedule(id)} />
                {id === showScheduleId && (
                  <div className={styles.schedule}>
                    <StoreTimeTable store={getStoreById(id)} />
                  </div>
                )}
              </td>
              <td>
                <div className={styles.review}>
                  <button type="button" onClick={() => writeReview(id)}>
                    {t('Table.write review')}
                  </button>
                </div>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

export default StoresTable;
