import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { useComplectationConfirm } from 'features/modals/comlectation-forget';
import styles from './placeOrder.module.scss';
import ButtonPlaceOrder from './ButtonPlaceOrder';

interface IPlaceOrder {
  isPickupProducts: boolean;
  isDeliveryProducts: boolean;
}

const getTextPlaceOrder = ({
  t,
  isPickupProducts,
  isDeliveryProducts,
}: {
  t: TFunction;
  isPickupProducts: boolean;
  isDeliveryProducts: boolean;
}): string | undefined => {
  if (isPickupProducts && isDeliveryProducts)
    return t('NewCart.bothRestriction');
  if (isPickupProducts) return t('NewCart.pickupRestriction');
  if (isDeliveryProducts) return t('NewCart.delivery_restriction');
  return undefined;
};

export function PlaceOrder({
  isPickupProducts,
  isDeliveryProducts,
}: IPlaceOrder) {
  const { t } = useTranslation();
  const { isConfirmed, setConfirmed } = useComplectationConfirm();

  const textPlaceOrder = getTextPlaceOrder({
    t,
    isPickupProducts,
    isDeliveryProducts,
  });

  return (
    <div className={styles.placeOrder}>
      <h3 className={styles.title}>{t('localization.placeYourOrder')}</h3>
      {textPlaceOrder && <p className={styles.warning}>{textPlaceOrder}</p>}

      <div className={styles.buttons}>
        <ButtonPlaceOrder
          isDelivery
          isDisabledProducts={!!isPickupProducts}
          isConfirmed={isConfirmed}
          setConfirmed={setConfirmed}
        />
        <ButtonPlaceOrder
          isDelivery={false}
          isDisabledProducts={!!isDeliveryProducts}
          isConfirmed={isConfirmed}
          setConfirmed={setConfirmed}
        />
      </div>
    </div>
  );
}
