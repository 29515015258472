import parse, {
  attributesToProps,
  domToReact,
  DOMNode,
} from 'html-react-parser';

const htmlParser = <P = Record<PropertyKey, string>>(
  data: string,
  replaceData?: {
    from: string;
    to: (props: P, children: DOMNode[]) => JSX.Element;
  }
): JSX.Element | null => {
  if (!data) return null;
  try {
    const replace = (domNode: DOMNode) => {
      if (domNode.type === 'tag') {
        const elementNode = domNode as {
          attribs: Record<PropertyKey, string>;
          name: string;
          children: DOMNode[];
        };
        if (elementNode.attribs && elementNode.name === replaceData?.from) {
          const props = attributesToProps(elementNode.attribs);
          return replaceData?.to(
            props as P,
            domToReact(elementNode.children, { replace })
          );
        }
      }
      return domNode;
    };

    return parse(data, replaceData ? { replace } : undefined);
  } catch (e: any) {
    throw new Error(e);
  }
};

export default htmlParser;
