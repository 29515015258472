import { useCookies } from 'react-cookie';
import client from 'modules/config/client';

export const useMemorizer = <T extends string>(
  key: T
): {
  set: (value: string) => void;
  get: () => T | undefined;
} => {
  const [cookie, setCookie] = useCookies([key]);
  return {
    set: (value) =>
      setCookie(key, value, {
        path: '/',
        maxAge: client('client').cookieMaxAge,
      }),
    get: () => cookie[key],
  };
};
